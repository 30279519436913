import { useState } from "react";
import { useNavigate } from "react-router-dom";

import CreatePayrollModal from "./components/CreatePayrollModal";
import Modal from "../../../../shared/components/ui/Modal";
import PageLoading from "../../../../shared/components/ui/PageLoading";
import PageSearchIconAndLabel from "../../../../shared/components/PageSearchIconAndLabel";
import PaginationAllData from "../../../../shared/components/pagination/PaginationAllData";
import PayrollCard from "./components/PayrollCard";
import PayrollTableHeader from "./components/PayrollTableHeader";
import SearchField from "../../../../shared/components/ui/SearchField";
import { Delete, Eye } from "../../../../shared/components/icons";
import { AddButtonPlain } from "../../../../shared/components/ui/Buttons";

import {
  extractFirstWordToUpperCase,
  paginate,
} from "../../../../utils/helperFunctions";
import { usePayroll } from "../../store/payrollStore/payrollContext";
import WalletBalanceUi from "./components/WalletBalanceUi";

const PayrollManager = () => {
  const { state } = usePayroll();

  const { isLoading, error, payrolls } = state;

  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);

  const [currentIndex, setCurrentIndex] = useState(1);

  const handlePageChange = (page, _) => {
    setCurrentIndex(page);
  };

  const uiBuilder = () => {
    if (error) {
      return (
        <div className="flex h-full items-center justify-center">
          <PageSearchIconAndLabel label={error} />
        </div>
      );
    }

    if (isLoading) {
      return (
        <div className="flex h-full items-center justify-center">
          <PageLoading />
        </div>
      );
    }

    if (payrolls.length === 0) {
      return (
        <div className="flex h-full items-center justify-center">
          <PageSearchIconAndLabel label="No payroll created" />
        </div>
      );
    }

    return (
      <div className="flex flex-col">
        <div className="rounded-b-[10px]">
          {/* overflow-x-auto scroll-smooth */}
          <table className="w-full text-left text-sm rtl:text-right">
            <PayrollTableHeader
              label1="Year"
              label2="Month"
              label3="Total Paid"
              label4="Payment Fee"
              label5="Status"
              label6="Action"
            />
            {paginate(payrolls, currentIndex, 15)?.map((payroll, index) => (
              <PayrollCard
                key={payroll?.id ?? index}
                data1={payroll?.year ?? "-- --"}
                data2={extractFirstWordToUpperCase(payroll?.month) ?? "-- --"}
                data3={payroll?.totalSalaryAmount ?? "-- --"}
                data4={payroll?.totalSalaryFee ?? "-- --"}
                data5={payroll?.PayrollProcessingState ?? "-- --"}
                actionIcon={<Eye />}
                onActionIconClicked={() =>
                  navigate(payroll.id, { state: { payroll: payroll } })
                }
              />
            ))}
          </table>
        </div>
        <PaginationAllData
          className="mt-[12px]"
          totalData={payrolls?.length ?? 0}
          currentPage={currentIndex}
          pageSize={15}
          onChangePage={handlePageChange}
        />
      </div>
    );
  };

  return (
    <>
      <Modal
        showModal={showModal}
        isDismissible={true}
        onClose={() => setShowModal(false)}
      >
        <CreatePayrollModal />
      </Modal>
      <div className="mb-[12px] flex flex-col">
        <h1 className="mb-[24px] text-[28px] font-semibold text-black-shade10">
          Payroll Manager
        </h1>

        <WalletBalanceUi />

        <div className="flex h-auto w-full flex-col rounded-[10px] border-[1px] border-solid border-[#EAEBF0] px-[16px] pt-[24px] pb-[12px]">
          <div className="flex items-center justify-end">
            {/* <div className="flex items-center gap-[16px]">
              <Delete />
              <SearchField />
            </div> */}
            <div className="mb-[16px] flex items-center justify-between gap-[12px]">
              <AddButtonPlain
                showIcon={false}
                className={`h-[40px] bg-[#437EF7] px-[40px]`}
                clicked={() => setShowModal(true)}
              >
                Create payroll
              </AddButtonPlain>
            </div>
          </div>

          {uiBuilder()}
        </div>
      </div>
    </>
  );
};

export default PayrollManager;
