import React from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import HomeRoute from "./common/HomeRoute";
import ProtectedRoute from "./common/ProtectedRoute";
import ProtectedRouteFromAuthenticatedUser from "./common/ProtectedRouteFromAuthenticatedUser";
import DashboardSelector from "./common/DashboardSelector";

import Users from "./shared/pages/users/Users";
import Marks from "./shared/pages/assessments/Marks";
import Assessments from "./shared/pages/assessments/Assessments";
import BackOffice from "./shared/pages/backOffice/BackOffice";
import Academic from "./shared/pages/academic/Academic";
import Onboarding from "./shared/pages/schoolRegistration/Onboarding";

import Login from "./shared/pages/Login";
import CreateSchoolAccount from "./shared/pages/schoolRegistration/CreateSchoolAccount";
import EnterEmailCode from "./shared/pages/schoolRegistration/EnterEmailCode";
import VerifySentCode from "./shared/pages/VerifySentCode";
import ForgotPassword from "./shared/pages/ForgotPassword";
import Teachers from "./shared/pages/users/Teachers";
import PasswordRecoveryEmail from "./shared/pages/PasswordRecoveryEmail";
import Students from "./shared/pages/users/Students";
import CreateNewPassword from "./shared/pages/CreateNewPassword";
import Subjects from "./shared/pages/Subjects";
import Results from "./shared/pages/assessments/results/Results";
import Examinations from "./shared/pages/examinations/Examinations";
import Class from "./shared/pages/academic/class/Class";
import Session from "./shared/pages/backOffice/session/Session";
import ResultComments from "./shared/pages/assessments/resultComments/ResultComments";
import Promotion from "./features/school/components/promotion/Promotion";
import Support from "./shared/pages/Support";
import PageNotFound from "./shared/pages/PageNotFound";

import { contextClass } from "./config/toastifyConfig";
import { AuthProvider } from "./store/authContext";
import { MarksProvider } from "./store/marksStore/marksContext";
import { StudentProvider } from "./store/studentStore/studentContext";
import { TeacherProvider } from "./store/teacherStore/teacherContext";
import { SubjectProvider } from "./store/subjectStore/subjectContext";
import { PromotionProvider } from "./features/school/store/promotionStore/promotionContext";

import ComingSoon from "./shared/components/ComingSoon";

import { comingSoonRoutes } from "./utils/routeNames";
import { routes } from "./config/routes";
import Payroll from "./features/school/pages/payroll/Payroll";
import PayrollManager from "./features/school/pages/payroll/PayrollManager";
import PayrollItem from "./features/school/pages/payroll/PayrollItem";
import CreatePayroll from "./features/school/pages/CreatePayroll";
import PrerequisiteSteps from "./features/school/prerequisite/components/PrerequisiteSteps";

function App() {
  return (
    <>
      <ToastContainer
        toastClassName={({ type }) =>
          contextClass[type || "default"] +
          " relative flex py-1 px-6 min-h-10 rounded-[14px] text-white justify-between overflow-hidden cursor-pointer"
        }
        icon={false}
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        closeOnClick={false}
        pauseOnHover={true}
        draggable={false}
        // progress={undefined}
      />
      <AuthProvider>
        {/* TODO: Move routing to a separate file */}
        <Routes>
          <Route path={routes.HOME} element={<HomeRoute />} />
          <Route path={routes.LOGIN} element={<Login />} />
          <Route path={routes.ONBOARDING} element={<Onboarding />} />
          <Route path={routes.ENTER_CODE} element={<EnterEmailCode />} />
          <Route
            path={routes.CREATE_NEW_PASSWORD}
            element={<CreateNewPassword />}
          />
          <Route path={routes.VERIFY_SENT_CODE} element={<VerifySentCode />} />

          <Route element={<ProtectedRouteFromAuthenticatedUser />}>
            <Route path={routes.REGISTER} element={<CreateSchoolAccount />} />
            <Route path={routes.FORGOT_PASSWORD} element={<ForgotPassword />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path={routes.DASHBOARD} element={<DashboardSelector />} />

            <Route path="prerequisite" element={<PrerequisiteSteps />} />

            <Route path={routes.USERS} element={<Users />}>
              <Route index element={<Teachers />} />
              <Route
                path={routes.TEACHERS}
                element={
                  <TeacherProvider>
                    <Teachers />
                  </TeacherProvider>
                }
              />
              <Route
                path={routes.STUDENTS}
                element={
                  <StudentProvider>
                    <Students />
                  </StudentProvider>
                }
              />
            </Route>

            <Route path={routes.ASSESSMENTS} element={<Assessments />}>
              <Route
                path={routes.MARKS}
                element={
                  <MarksProvider>
                    <Marks />
                  </MarksProvider>
                }
              />
              <Route path={routes.EXAM_TYPES} element={<Examinations />} />
              <Route
                path={routes.RESULT_COMMENTS}
                element={<ResultComments />}
              />
              <Route
                path={routes.PROMOTION}
                element={
                  <PromotionProvider>
                    <Promotion />
                  </PromotionProvider>
                }
              />
              <Route path={routes.RESULTS} element={<Results />} />
            </Route>
            {Object.values(comingSoonRoutes).map((route, index) => (
              <Route path={route} key={index} element={<ComingSoon />} />
            ))}

            <Route path={routes.BACK_OFFICE} element={<BackOffice />}>
              <Route path={routes.SESSION} element={<Session />} />

              <Route element={<Payroll />}>
                <Route path="payroll-manager" element={<PayrollManager />} />
                <Route
                  path="payroll-manager/create-payroll"
                  element={<CreatePayroll />}
                />
                <Route path="payroll-manager/:id" element={<PayrollItem />} />
              </Route>
            </Route>

            <Route path={routes.ACADEMIC} element={<Academic />}>
              <Route
                path={routes.SUBJECTS}
                element={
                  <SubjectProvider>
                    <Subjects />
                  </SubjectProvider>
                }
              />
              <Route path={routes.CLASS} element={<Class />} />
            </Route>

            <Route path={routes.ACCOUNTING} element={<ComingSoon />} />
            <Route path={routes.SETTINGS} element={<ComingSoon />} />

            <Route path={routes.SUPPORT} element={<Support />} />
          </Route>
          <Route
            path={routes.PASSWORD_RECOVERY_EMAIL}
            element={<PasswordRecoveryEmail />}
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </AuthProvider>
    </>
  );
}

export default App;
