import LoadingShimmer from "../../../../../shared/components/ui/loadingShimmer/LoadingShimmer";
import useGetWallet from "../../../hooks/useGetWallet";

const WalletBalanceUi = () => {
  const { isLoading, error, walletBalance } = useGetWallet();

  return (
    <>
      {isLoading && <LoadingShimmer height="h-[110px] mb-[16px]" />}

      {!isLoading && !error && (
        <div className="mb-[16px] flex flex-col rounded-[10px] border-[1px] border-solid border-grey-shade15 bg-white py-[16px] px-[20px]">
          <p className="mb-[20px] self-start text-[28px] font-semibold text-black-shade10">
            ₦{" "}
            {walletBalance.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}
          </p>
          <span className="text-[14px] font-medium text-black-shade10">
            Wallet balance
          </span>
        </div>
      )}
    </>
  );
};

export default WalletBalanceUi;
